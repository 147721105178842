<template>
  <v-card class="d-flex flex-column">
    <v-snackbar
      content-class="centered"
      top
      :timeout="3000"
      v-model="snackbar"
      >{{ snackbarText }}</v-snackbar
    >
    <v-card-title class="grid-close">
      <span class="headline"
        >{{ type == "add" ? $tc("dialog.add") : $tc("dialog.edit") }}
        {{ $tc("playlists.title") }}</span
      >
      <v-btn
        style="right: 10px; top: 10px; border-radius: 30px !important"
        icon
        color="primary"
        fab
        absolute
        @click="$emit('cancel')"
        v-if="!loading"
      >
        <v-icon dark>fa-times</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-container fluid class="pa-5">
        <v-row class="pa-0 ma-0">
          <v-col class="mt-2" cols="12" sm="6" md="6">
            <v-text-field
              v-model="playlist.name"
              :label="$tc('dialog.name')"
              clearable
              rounded
              filled
            ></v-text-field>

            <v-text-field
              v-model.number="playlist.imageDuration"
              :label="$tc('playlists.image_duration_time')"
              clearable
              rounded
              filled
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="6">
            <v-textarea
              v-model="playlist.description"
              :label="$tc('dialog.description')"
              clearable
              rows="4"
              class="mt-2"
              rounded
              filled
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-0 ma-0">
          <v-col cols="12" sm="6" md="6">
            <div class="main-cards-container">
              <p class="font-weight-bold">
                {{ $tc("playlists.all_files") }} ({{ getFiles.length }})
              </p>
              <v-text-field
                v-model="searchFile"
                clearable
                rounded
                :loading="loading"
                flat
                filled
                hide-details
                :label="$tc('button.search')"
                prepend-inner-icon="fa-search"
                class="d-md-flex"
              />
              <v-list class="cards-container" color="card" three-line>
                <draggable
                  @change="leftSideEdited"
                  itemKey="id"
                  class="list-group"
                  :list="getFiles"
                  group="newFiles"
                >
                  <template v-for="item in getFiles">
                    <v-list-item class="list-item" :key="item.id">
                      <img
                        class="image"
                        @click="showFile(item)"
                        :src="item.screenshot"
                      />
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="item.name"
                        ></v-list-item-title>
                        <v-list-item-subtitle>
                          <div>
                            {{ item.type == "video" ? "Video" : "Imagen" }}
                            <span class="font-weight-bold"
                              >({{ item.size | filterSize }})</span
                            >
                          </div>
                          <div class="mt-3 d-none d-md-block d-sm-block">
                            {{ $tc("videos.uploaded_at") }}
                            <span class="font-weight-bold">
                              {{ item.createdAt | filterDate }}
                            </span>
                          </div>
                        </v-list-item-subtitle>
                        <span
                          v-if="item.duration"
                          class="duration font-weight-bold d-none"
                          >{{ item.duration | filterDuration }} min</span
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </draggable>
              </v-list>
            </div>
          </v-col>

          <v-col cols="12" sm="6" md="6">
            <div class="main-cards-container">
              <p style="position: relative" class="font-weight-bold">
                {{ $tc("playlists.files_in_playlist") }} ({{
                  getPlaylistFiles.length
                }})
                <span class="size"
                  ><b>
                    {{ $tc("playlists.size") }}:
                    {{ calculateSize | filterSize }}</b
                  ></span
                >
              </p>
              <v-text-field
                v-model="searchPlaylistFile"
                clearable
                :loading="loading"
                rounded
                flat
                filled
                hide-details
                :label="$tc('button.search')"
                prepend-inner-icon="fa-search"
                class="d-md-flex"
              />

              <v-list class="cards-container" color="card" three-line>
                <draggable
                  @change="rightSideEdited"
                  itemKey="id"
                  class="list-group"
                  :list="getPlaylistFiles"
                  group="newFiles"
                >
                  <template v-for="item in getPlaylistFiles">
                    <v-list-item class="list-item" :key="item.id">
                      <img
                        @click="showFile(item)"
                        class="image"
                        :src="item.screenshot"
                      />
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="item.name"
                        ></v-list-item-title>
                        <v-list-item-subtitle>
                          <div>
                            {{ item.type == "video" ? "Video" : "Imagen" }}
                            <span class="font-weight-bold"
                              >({{ item.size | filterSize }})</span
                            >
                          </div>
                          <div class="mt-3 d-none d-md-block d-sm-block">
                            {{ $tc("videos.uploaded_at") }}
                            <span class="font-weight-bold">
                              {{ item.createdAt | filterDate }}
                            </span>
                          </div>
                        </v-list-item-subtitle>
                        <i class="fas fa-bars icon d-none"></i>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </draggable>
              </v-list>
            </div>
          </v-col>
        </v-row>

        <v-row class="pa-0 ma-0">
          <v-col cols="12" sm="12" md="12">
            <v-row justify="end">
              <v-btn
                @click="type == 'add' ? save() : update()"
                :loading="loading"
                color="buttons"
                style="min-width: 120px"
                class="mr-3 mt-3"
                >{{ $tc("button.save") }}</v-btn
              >
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-dialog
      hide-overlay
      fullscreen
      v-if="viewFileDialog"
      v-model="viewFileDialog"
    >
      <view-file
        :url="selectedFile.url"
        :type="selectedFile.type"
        @cancel="viewFileDialog = false"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { db, fb } from "@/firebase";
import { mapActions, mapState } from "vuex";
import draggable from "vuedraggable";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import viewFile from "@/components/view-file";

export default {
  name: "add-playlist",
  props: ["type", "originalPlaylist"],
  components: {
    draggable,
    viewFile,
  },
  data() {
    return {
      snackbarText: "",
      snackbar: false,
      loading: true,
      viewFileDialog: false,
      selectedFile: null,
      playlist: {
        name: "",
        description: "",
        imageDuration: 10,
      },
      files: [],
      playlistFiles: [],
      searchFile: "",
      searchPlaylistFile: "",
    };
  },
  filters: {
    filterDate(timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate()).tz("America/Tegucigalpa").format("L");
    },
    filterSize(size) {
      let mb = size / 1048576;
      let gb = mb > 1024 ? mb / 1025 : 0;

      return gb ? `${gb.toFixed(2)} GB` : `${mb.toFixed(2)} MB`;
    },
    filterDuration(duration) {
      let result = 0;
      if (duration) {
        let minutes = parseInt(duration / 60);
        let seconds = parseInt(duration % 60);

        result = `${minutes}:${seconds}`;
      }

      return result;
    },
  },
  computed: {
    ...mapState(["user", "groupId"]),
    getFiles() {
      if (!this.searchFile) return this.files;
      else
        return this.files.filter((item) =>
          item.name.toLowerCase().includes(this.searchFile.toLowerCase())
        );
    },

    calculateSize() {
      return this.getPlaylistFiles.reduce((a, b) => a + (b.size || 0), 0);
    },

    getPlaylistFiles() {
      if (!this.searchPlaylistFile) return this.playlistFiles;
      else
        return this.playlistFiles.filter((item) =>
          item.name
            .toLowerCase()
            .includes(this.searchPlaylistFile.toLowerCase())
        );
    },
  },

  methods: {
    sortedArray: function (data) {
      function compare(a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }
      return data.sort(compare);
    },

    leftSideEdited(e) {
      if (e.removed) {
        this.files = this.files.filter(
          (item) => item.id != e.removed.element.id
        );
      }
    },

    rightSideEdited(e) {
      if (e.removed) {
        this.playlistFiles = this.playlistFiles.filter(
          (item) => item.id != e.removed.element.id
        );
      }
    },

    showFile(file) {
      this.selectedFile = file;
      this.viewFileDialog = true;
    },

    save() {
      if (this.playlist.name) {
        this.loading = true;
        this.playlist.createdAt = new Date();
        this.playlist.createdBy = this.user.uid;
        this.playlist.groupId = this.groupId;
        this.playlist.files = this.playlistFiles.map((item) => {
          return item.id;
        });

        this.playlist.screenshot = this.playlistFiles.length
          ? this.playlistFiles[0].screenshot
          : "";

        db.collection("playlists")
          .add(this.playlist)
          .then((response) => {
            this.resetFields();
          })
          .catch((error) => {
            this.loading = false;
            this.snackbarText = this.$tc("messages.unknown");
            this.snackbar = true;
          });
      } else {
        this.snackbarText = this.snackbarText = this.$tc("playlists.empty");
        this.snackbar = true;
      }
    },

    update() {
      if (this.playlist.name) {
        delete this.playlist.groupId;
        this.loading = true;
        this.playlist.modifiedAt = new Date();
        this.playlist.modifiedBy = this.user.uid;
        this.playlist.files = this.playlistFiles.map((item) => {
          return item.id;
        });
        this.playlist.screenshot = this.playlistFiles.length
          ? this.playlistFiles[0].screenshot
          : "";

        db.collection("playlists")
          .doc(this.playlist.id)
          .update(this.playlist)
          .then((response) => {
            this.resetFields();
          })
          .catch((error) => {
            this.loading = false;
            this.snackbarText = this.$tc("messages.unknown");
            this.snackbar = true;
          });
      } else {
        this.snackbarText = this.$tc("playlists.empty");
        this.snackbar = true;
      }
    },

    resetFields() {
      this.playlist = {
        name: "",
        description: "",
        imageDuration: 10,
      };
      this.files = [];
      this.playlistFiles = [];
      this.$emit("success");
    },
  },

  async mounted() {
    await db
      .collection("files")
      .where("groupId", "==", this.groupId)
      .get()
      .then((response) => {
        response.forEach((item) => {
          let file = item.data();
          file.id = item.id;
          this.files.push(file);
        });
        this.loading = false;
      });

    if (this.originalPlaylist) {
      this.playlist = Object.assign({}, this.originalPlaylist);

      this.playlistFiles = this.files.filter((item) =>
        this.playlist.files.includes(item.id)
      );

      let sortedPlaylist = [];
      this.playlistFiles.forEach((item) => {
        let index = this.playlist.files.indexOf(item.id);
        sortedPlaylist[index] = item;
      });

      this.playlistFiles = sortedPlaylist.filter((item) => item);

      this.files = this.files.filter(
        (item) => !this.playlist.files.includes(item.id)
      );
    }
  },
};
</script>

<style scoped lang="scss">
@import "../../../responsive";

.list-group {
  min-width: 400px;
  min-height: 300px;
}

.main-cards-container {
  background-color: #eeeeee;
  border-radius: 20px;
  padding: 20px;
  /* min-height: 400px;
  max-height: 400px;
  overflow-y: auto; */
  @include responsive(mobile) {
    width: 100%;
  }
}

.cards-container {
  background-color: #eeeeee;
  border-radius: 20px;
  min-height: 300px;
  max-height: 300px;
  overflow-y: auto;
  margin-top: 10px;
}

.image {
  width: 100px;
  margin: 15px;
  cursor: pointer;
  object-fit: cover;
  height: 100px;
}

@media only screen and (max-width: 600px) {
  .cards-container {
    padding: 10px;
    width: 100%;
    min-height: 200px;
    max-height: 200px;
    overflow-x: hidden;
  }

  .image {
    cursor: pointer;
    object-fit: cover;
    height: 60px;
    width: 60px;
    padding: 0;
    margin: 0 10px 0 0;
  }
}

.list-item {
  cursor: move;
  display: flex;
  padding: 0;
}
.icon {
  position: absolute;
  right: 20px;
  font-size: 20px;
  color: gray;
}

.duration {
  position: absolute;
  right: 20px;
  color: gray;
}

.size {
  position: absolute;
  right: 0;
  color: rgb(6, 66, 119);
  font-size: 15px;
  font-weight: bold;
}
</style>