<template>
  <v-container class="main-cont">
    <v-icon @click="$emit('cancel')" class="close">fas fa-times</v-icon>
    <v-row>
      <v-col class="preview-container" cols="12" sm="12" md="12">
        <img v-if="showImage" class="media-control" :src="url" />
        <video
          controls
          v-if="showVideo"
          class="media-control"
          :src="url"
        ></video>
      </v-col>
      <v-col
        style="display: flex; justify-content: center"
        cols="12"
        sm="12"
        md="12"
      >
        <div class="cards-scroll">
          <v-tooltip bottom v-for="(item, index) of source" :key="index">
            <template v-slot:activator="{ on }">
              <img
                v-on="on"
                class="thumbnail"
                @click="playFile(item)"
                :src="
                  item.screenshot ||
                  'https://firebasestorage.googleapis.com/v0/b/beanage-data/o/defaultImage.svg?alt=media&token=fbada6c0-836b-45f2-bbc9-2931f7e1aa12'
                "
                height="80px"
                width="80px"
                :style="
                  selectedFile == item.url
                    ? 'border-bottom: 5px solid #008BBF; opacity: 0.5;'
                    : ''
                "
              />
            </template>
            <span>Clic para ver el contenido.</span>
          </v-tooltip>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { db, fb } from "@/firebase";
import { mapActions, mapState } from "vuex";

export default {
  name: "view-playlist",
  props: ["files", "name"],
  data() {
    return {
      source: [],
      showVideo: false,
      showImage: false,
      url: "",
      selectedFile: "",
      lazy: "",
    };
  },

  methods: {
    playFile(item) {
      let file = item ? item : this.source[0];
      this.selectedFile = file.url;
      this.url = file.url;
      this.lazy = file.screenshot ? file.screenshot : "";

      if (file.type == "video") {
        this.showVideo = true;
        this.showImage = false;
      } else {
        this.showVideo = false;
        this.showImage = true;
      }
    },
  },
  async mounted() {
    for (let i = 0; i < this.files.length; i = i + 10) {
      let tempArray = this.files.slice(i, i + 10);
      await db
        .collection(`files`)
        .where(fb.firestore.FieldPath.documentId(), "in", tempArray)
        .get()
        .then((response) => {
          response.forEach((item) => {
            this.source.push({
              url: item.data().url,
              screenshot: item.data().screenshot,
              type: item.data().type,
              id: item.id,
            });
          });
        });
    }
    this.playFile();
  },
};
</script>

<style scoped>
.cards-scroll img {
  border-radius: 5px;
  margin: 10px;
}
.close {
  position: absolute;
  top: 40px;
  right: 40px;
  color: white;
  font-size: 30px !important;
  font-weight: bold;
  cursor: pointer;
  z-index: 9999;
}
.cards-scroll {
  overflow-x: auto;
  max-width: 100%;
  height: 20vh;
  display: flex;
  justify-content: flex-start;
}
.media-control {
  object-fit: contain;
  max-width: 100%;
  max-height: 60vh;
}

.preview-container {
  min-height: 60vh;
  display: flex;
  margin-top: 50px;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .media-control {
    object-fit: contain;
    max-width: 80%;
  }
  .cards-scroll {
    overflow-x: auto;
    max-width: 80%;
    height: 15vh;
    display: flex;
    justify-content: flex-start;
  }
}

.thumbnail {
  cursor: pointer;
  object-fit: fill;
}
</style>