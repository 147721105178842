<template>
  <div>
    <v-snackbar
      content-class="centered"
      absolute
      top
      :timeout="3000"
      v-model="snackbar"
      >{{ snackbarText }}</v-snackbar
    >
    <v-container fluid>
      <v-row class="header-title-actions">
        <v-col cols="12" sm="6" md="6">
          <h1 class="page-title">{{ $tc("playlist", 0) }}</h1>
        </v-col>

        <v-col class="align-end" cols="12" sm="6" md="6">
          <v-btn
            color="buttons"
            @click="showAddPlaylistDialog"
            :loading="loading"
          >
            <v-icon left> fas fa-plus </v-icon>
            {{ $tc("playlists.add") }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mt-5">
        <!-- <v-col cols="12" sm="12" md="12">
          <search :loading="loading" :name="$tc('playlist', 1)" />
        </v-col> -->

        <v-col cols="12" sm="12" md="12">
          <files-table
            @menuClick="handleMenuActions($event)"
            :items="playlists"
            :loading="loading"
            :showView="true"
            :showEdit="true"
            :showDelete="true"
            :headers="headers"
            :appendRow="true"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-if="addPlaylistDialog"
      persistent
      v-model="addPlaylistDialog"
      max-width="1200px"
      scrollable
    >
      <add-playlist
        :originalPlaylist="selectedPlaylist"
        :type="operationType"
        @success="playlistAdded"
        @cancel="addPlaylistDialog = false"
      />
    </v-dialog>

    <v-dialog
      fullscreen
      hide-overlay
      v-if="viewPlaylistDialog"
      v-model="viewPlaylistDialog"
    >
      <view-playlist
        :name="selectedPlaylist.name"
        :files="selectedPlaylist.files"
        @cancel="viewPlaylistDialog = false"
      />
    </v-dialog>

    <v-dialog
      max-width="500px"
      v-if="deleteVideoDialog"
      v-model="deleteVideoDialog"
    >
      <delete-image
        ref="deleteComponent"
        :name="selectedPlaylist.name"
        @success="confirmDelete"
        @cancel="deleteVideoDialog = false"
      />
    </v-dialog>
  </div>
</template>
<script>
import { db, fb } from "@/firebase";
import { mapActions, mapState } from "vuex";
import addPlaylist from "./add-playlist";
import viewPlaylist from "./view-playlist";
import deleteImage from "@/components/delete";
// import AddPlaylist from "./add-playlist.vue";
import filesTable from "@/components/files-table";

export default {
  name: "Playlists",
  components: {
    addPlaylist,
    viewPlaylist,
    deleteImage,
    filesTable,
  },
  data() {
    return {
      playlists: [],
      screens: [],
      snackbarText: "",
      snackbar: false,
      loading: true,
      addPlaylistDialog: false,
      selectedPlaylist: null,
      viewPlaylistDialog: false,
      deleteVideoDialog: false,
      operationType: "",
      files: [],
      headers: [
        {
          value: "image",
          width: "150px",
        },
        {
          text: this.$tc("headers.name"),
          value: "name",
          width: "150px",
        },
        {
          text: this.$tc("headers.description"),
          value: "description",
          width: "150px",
        },
        {
          text: this.$tc("headers.files"),
          value: "filesCount",
          width: "120px",
        },
        {
          text: this.$tc("headers.size"),
          value: "size",
          width: "200px",
        },
        {
          text: this.$tc("headers.image_transition"),
          value: "imageDuration",
          width: "170px",
        },
        {
          text: this.$tc("headers.createdAt"),
          value: "createdAt",
          width: "200px",
        },
        {
          value: "options",
          sortable: false,
          align: "end",
          width: "180px",
        },
      ],
    };
  },

  computed: {
    ...mapState(["groupId"]),
  },

  methods: {
    ...mapActions(["cleanSearch"]),
    handleMenuActions(payload) {
      switch (payload.action) {
        case "show_image":
          this.showViewPlaylistDialog(payload.item);
          break;
        case "edit":
          this.showEditPlaylistDialog(payload.item);
          break;
        case "delete":
          this.showDeleteDialog(payload.item);
          break;
      }
    },
    handleAnimation: function (anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },
    showAddPlaylistDialog() {
      this.selectedPlaylist = null;
      this.operationType = "add";
      this.addPlaylistDialog = true;
    },
    showEditPlaylistDialog(item) {
      this.operationType = "edit";
      this.selectedPlaylist = item;
      this.addPlaylistDialog = true;
    },
    showViewPlaylistDialog(item) {
      this.selectedPlaylist = item;
      this.viewPlaylistDialog = true;
    },

    showDeleteDialog(item) {
      this.selectedPlaylist = item;
      this.deleteVideoDialog = true;
    },
    async confirmDelete() {
      this.$refs.deleteComponent.loading = true;
      db.collection("playlists")
        .doc(this.selectedPlaylist.id)
        .delete()
        .then(() => {
          this.snackbarText = this.$tc("playlists.deleted");
          this.snackbar = true;
          this.$refs.deleteComponent.loading = false;
          this.deleteVideoDialog = false;
        })
        .catch(() => {
          this.snackbarText = this.$tc("messages.unknown");
          this.snackbar = true;
          this.$refs.deleteComponent.loading = false;
        });
    },

    playlistAdded() {
      this.addPlaylistDialog = false;
      this.snackbarText = this.selectedPlaylist
        ? this.$tc("playlists.updated")
        : this.$tc("playlists.added");
      this.snackbar = true;
    },
  },
  async mounted() {
    this.cleanSearch();

    await this.$binding(
      "files",
      db.collection("files").where("groupId", "==", this.groupId)
    );

    await db
      .collection("playlists")
      .where("groupId", "==", this.groupId)
      .onSnapshot((response) => {
        this.playlists = [];
        response.forEach((item) => {
          let playlist = item.data();
          playlist.id = item.id;

          let files = this.files.filter((e) =>
            playlist.files.includes(e[".key"])
          );

          playlist.size = files
            ? files.reduce((a, b) => a + (b.size || 0), 0)
            : 0;

          this.playlists.push(playlist);
        });
        this.loading = false;
      });
  },
};
</script>

<style>
.v-dialog--fullscreen {
  border-radius: 0 !important;
  background-color: #000000e3;
}
</style>